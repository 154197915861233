import React from "react"
import styled from "styled-components"
import {Link} from "gatsby"

const StyledWrapper = styled.div`
  text-align: right;
  * {
    word-break: break-word;
    align-items: center;
  }

  & img {
    margin-bottom: 0;
    margin-left: 2rem;
    width: 24px;
  }
`

const NextPost = props => {
  const nextArticle = props.pageContext
  return (
    <StyledWrapper>
      {nextArticle.next === null || nextArticle.next.frontmatter.topic !== nextArticle.topic ? (
        <Link to="/">
            <h5>Browse series</h5>
        </Link>
      ) : (
        <>
          <h6>Next Post</h6>
          <Link to={nextArticle.next.fields.slug}>
              <h5>{nextArticle.next.frontmatter.title}</h5>
          </Link>
        </>
      )}
    </StyledWrapper>
  )
}

export default NextPost
