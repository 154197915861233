import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledWrapper = styled.div`
  * {
    align-items: center;
    word-break: break-word;
    hyphens: auto;
  }
  & img {
    margin-bottom: 0;
    width: 24px;
    margin-right: 2rem;
  }

`

const PrevPost = props => {
  const prevArticle = props.pageContext
  return (
    <StyledWrapper>
      {prevArticle.prev === null ||  prevArticle.topic !== prevArticle.prev.frontmatter.topic ? (
        <Link to="/">
            <h5>Browse series</h5>
        </Link>
      ) : (
        <>
          <h6>Previous Post</h6>
          <StyledWrapper>
          <Link to={prevArticle.prev.fields.slug}>
              <h5> {prevArticle.prev.frontmatter.title}</h5>
          </Link>
        </StyledWrapper>
        </>
      )}
    </StyledWrapper>
  )
}

export default PrevPost
